import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import iPhone01 from '../../../../../images/Software/iOS/InstarVision/iPhone/IPhone_App_NEW_(1).webp'
import iPhone02 from '../../../../../images/Software/iOS/InstarVision/iPhone/IPhone_App_NEW_(2).webp'
import iPhone03 from '../../../../../images/Software/iOS/InstarVision/iPhone/IPhone_App_NEW_(3).webp'
import iPhone04 from '../../../../../images/Software/iOS/InstarVision/iPhone/IPhone_App_NEW_(4).webp'
import iPhone05 from '../../../../../images/Software/iOS/InstarVision/iPhone/IPhone_App_NEW_(5).webp'
import iPhone06 from '../../../../../images/Software/iOS/InstarVision/iPhone/IPhone_App_NEW_(6).webp'
import iPhone07 from '../../../../../images/Software/iOS/InstarVision/iPhone/IPhone_App_NEW_(7).webp'
import iPhone08 from '../../../../../images/Software/iOS/InstarVision/iPhone/IPhone_App_NEW_(8).webp'
import iPhone09 from '../../../../../images/Software/iOS/InstarVision/iPhone/IPhone_App_NEW_(9).webp'
import iPhone10 from '../../../../../images/Software/iOS/InstarVision/iPhone/IPhone_App_NEW_(10).webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPhone01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Go to the Multi View or Cameras menu and click the + button in the top right corner to add your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPhone02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Select New IP Camera to add a new IP camera."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPhone03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Either scan the QR Code from the web interface of your camera or select the camera model manually."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPhone04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Select the manufacturer of your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPhone05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Select your camera model. Then click on Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPhone06} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="After selecting the camera, press Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPhone07} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter a name for your camera and press Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPhone08} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter the DDNS address of your camera or the local IP address and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPhone09} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter the LAN (HTTP) port of your camera and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPhone10} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter the user name and then the password of your camera login of your camera login and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPhone10} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="You can tap the blue + to add a local IP and port. Now you can test your settings and then press Save to add the camera."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function DDNSGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default DDNSGallery